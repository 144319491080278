import React from 'react';
import RoundedContainer from './RoundedContainer'; // Adjust the import path to refer to the same folder
import CarStruckLogo from '../assets/CarStruckAR.png'; // Import the CarStruck logo
import './Containers.css'; // Import the new CSS file

function CarStruckContainer() {
    const height = 200;
    const width = 1000;

    return (
        <RoundedContainer height={height} width={width}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <img src={CarStruckLogo} alt="Car Struck AR Logo" className="AppLogo" />
                    <span className="AppTitle">Car Struck AR - Vision Solutions</span>
                </div>
                <div className="Description">
                    <p>An augmented reality app with car customization & racing.</p>
                </div>
                <div className="Spacing"></div> {/* Add spacing between description and bottom sections */}
            </div>
        </RoundedContainer>
    );
}

export default CarStruckContainer;
