import React from 'react';

function RoundedContainer({ height, width, children, style }) {
  const borderRadius = Math.min(height, width) / 10; // Example ratio for border radius
  const baseStyle = {
    height: height ? `${height}px` : 'auto',
    width: width ? `${width}px` : 'auto',
    backgroundColor: '#0f0f0f',
    border: '5px solid black',
    borderRadius: `${borderRadius}px`,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px',
    ...style // Allows custom styles to be passed
  };

  return (
    <div style={baseStyle}>
      {children}
    </div>
  );
}

export default RoundedContainer;
