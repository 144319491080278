import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import ProfileCircle from './assets/ProfilePicture.jpg';
import NetworkAnimation from './NetworkAnimation';

// Containers
import RiffTuneContainer from './Containers/RiffTuneContainer';
import CarStruckContainer from './Containers/CarStruckContainer';
import VisionSolutionsContainer from './Containers/VisionSolutionsContainer';

function App() {
  const [activeSection, setActiveSection] = useState('');
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  const handleSectionClick = (sectionName) => {
    const newActiveSection = activeSection === sectionName ? '' : sectionName;
    setActiveSection(newActiveSection);
  };

  return (
    <div className="background">
      <NetworkAnimation />
      <div className="App">
        <header ref={headerRef} className="App-header">
          <div className="HeaderGroup">
          <img src={ProfileCircle} alt="The Developer" className="ProfileCircle" />
            <div className="LeftSection">
              <h1 className="Name">Elliot Marsico</h1>
              <p className="Title">Software Developer</p>
            </div>
          </div>

          <ul className="RightSection">
            <li className="RightSectionItem" onClick={() => handleSectionClick('education')}>Education</li>
            <li className="RightSectionItem" onClick={() => handleSectionClick('projects')}>Projects</li>
            <li className="RightSectionItem" onClick={() => handleSectionClick('experience')}>Experience</li>
          </ul>
        </header>

        <div className={`ProjectsSubHeader ${activeSection === 'education' ? 'visible' : 'hidden'}`} style={{ top: `${headerHeight}px` }}>
          <ul className="RightSection">
            <li className="RightSectionItem" onClick={() => {}}>B.S. in Computer Science, Pennsylvania State University</li>
          </ul>
        </div>

        <div className={`ProjectsSubHeader ${activeSection === 'projects' ? 'visible' : 'hidden'}`} style={{ top: `${headerHeight}px` }}>
          <ul className="RightSection">
            <li className="RightSectionItem" onClick={() => {}}>RiffTune - Swift in XCode</li>
            <li className="RightSectionItem" onClick={() => {}}>Vision Solutions AR - C# in Unity</li>
            <li className="RightSectionItem" onClick={() => {}}>Car Struck AR - C# in Unity</li>
          </ul>
        </div>

        <div className={`ProjectsSubHeader ${activeSection === 'experience' ? 'visible' : 'hidden'}`} style={{ top: `${headerHeight}px` }}>
          <ul className="RightSection">
            <li className="RightSectionItem" onClick={() => {}}>Lead Software Engineer - Vision Solutions AR (2022 - Current)</li>
          </ul>
        </div>

        <div className="Content" style={{ paddingTop: `${headerHeight}px` }}>
          <div className="ScrollContainer">
            <RiffTuneContainer />
            <VisionSolutionsContainer />
            <CarStruckContainer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
