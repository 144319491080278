import React, { useRef, useEffect } from 'react';
import './App.css'; // Assuming you have your CSS in App.css

const maxDistanceToConnect = 200; // Max distance to draw a line between nodes
const nodeCount = 200; // Total number of nodes
const maxConnectionsPerNode = 10; // Maximum connections a node can have

// Node class to create new nodes
class Node {
    constructor(canvasWidth, canvasHeight) {
        this.x = Math.random() * canvasWidth;
        this.y = Math.random() * canvasHeight;
        this.velocity = {
            x: (Math.random() - 0.5),
            y: (Math.random() - 0.5)
        };
        this.radius = Math.random() * 3;
        this.connections = 0; // Track number of connections
    }

    draw(ctx) {
        // Draw the actual circle
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = "#09FBF9"; // Circle color
        ctx.fill();

        // Enhance the glow effect
        const glowRadius = this.radius * 10; // Increase the glow radius for a more pronounced effect
        const gradient = ctx.createRadialGradient(
            this.x, this.y, this.radius, // start at the circle's edge
            this.x, this.y, glowRadius // extend the glow further out
        );
        gradient.addColorStop(0, "rgba(9, 251, 249, 0.6)"); // Starting color with alpha for glow
        gradient.addColorStop(0.1, "rgba(9, 251, 249, 0.1)"); // Middle color, more transparent
        gradient.addColorStop(1, "rgba(9, 251, 249, 0)"); // Fully transparent at the edge

        // Draw the glow
        ctx.beginPath();
        ctx.arc(this.x, this.y, glowRadius, 0, Math.PI * 2);
        ctx.fillStyle = gradient;
        ctx.fill();
    }

    update(canvasWidth, canvasHeight) {
        if (this.x > canvasWidth || this.x < 0) {
            this.velocity.x = -this.velocity.x;
        }
        if (this.y > canvasHeight || this.y < 0) {
            this.velocity.y = -this.velocity.y;
        }
        this.x += this.velocity.x;
        this.y += this.velocity.y;
        this.connections = 0; // Reset connections for the next frame
    }
}

// React component
const NetworkAnimation = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        // Function to adjust the canvas size
        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        // Call resizeCanvas initially and whenever the window resizes
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        const nodes = Array.from({ length: nodeCount }, () => new Node(canvas.width, canvas.height));

        // Function to connect nodes with a limited number of connections
        const connectNodes = (nodes) => {
            nodes.forEach(node => {
                // Create an array of distances to other nodes
                let distances = nodes.map(otherNode => {
                    return {
                        node: otherNode,
                        distance: Math.sqrt((node.x - otherNode.x) ** 2 + (node.y - otherNode.y) ** 2)
                    };
                });

                // Filter and sort by distance
                distances = distances.filter(n => n.node !== node && node.connections < maxConnectionsPerNode && n.node.connections < maxConnectionsPerNode && n.distance < maxDistanceToConnect);
                distances.sort((a, b) => a.distance - b.distance);

                // Connect to the closest nodes
                distances.slice(0, maxConnectionsPerNode).forEach(connection => {
                    node.connections++;
                    connection.node.connections++;
                    ctx.lineWidth = 0.15;
                    ctx.strokeStyle = "#FFFFFF";
                    ctx.beginPath();
                    ctx.moveTo(node.x, node.y);
                    ctx.lineTo(connection.node.x, connection.node.y);
                    ctx.stroke();
                });
            });
        };

        // The animation function
        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            nodes.forEach(node => {
                node.update(canvas.width, canvas.height);
                node.draw(ctx);
            });
            connectNodes(nodes);
            animationFrameId = requestAnimationFrame(animate);
        };

        animate();

        // Clean up function to cancel animation frame when the component unmounts
        return () => {
            cancelAnimationFrame(animationFrameId);
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    return <canvas ref={canvasRef} className="canvas" />;
};

export default NetworkAnimation;
