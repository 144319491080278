import React from 'react';
import RoundedContainer from './RoundedContainer'; // Adjust the import path to refer to the same folder
import RiffTuneLogo from '../assets/RiffTune.png'; // Import the RiffTune logo
import './Containers.css'; // Import the new CSS file

function RiffTuneContainer() {
    const height = 400;
    const width = 1000;

    return (
        <RoundedContainer height={height} width={width}>
            <div style={{ width: '100%', height: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                    <img src={RiffTuneLogo} alt="RiffTune Logo" className="AppLogo" />
                    <span className="AppTitle">RiffTune</span>
                </div>
                <div className="Description">
                    <p>A stylized guitar tuner & metronome for iOS!</p>
                </div>

                <div className="Spacing"></div> {/* Add spacing between description and bottom sections */}

                <div className="Subheading">Privacy</div>
                <div className="StandardText">
                    <p>RiffTune does not collect any data from its users.</p>
                </div>
                <div className="Subheading">Contact</div>
                <div className="StandardText">
                    <p>For assistance, recommendations, or business inquiries contact at elliot.marsico@gmail.com</p>
                </div>
            </div>
        </RoundedContainer>
    );
}

export default RiffTuneContainer;
